import { RouteRecordRaw } from "vue-router";
import ClaimsPage from "@/modules/Calims/ClaimsPage.vue";
import HomeView from "@/views/HomeView.vue";

const claimsRoutes: Array<RouteRecordRaw> = [
    {
        path: "/schaden",
        component: ClaimsPage,
        meta: {
            noAuth: true,
        },
    },
    {
        path: "/schadenonline",
        name: "schadenonline",
        component: {},
        beforeEnter() {
//            if(new Date()<new Date("2024-11-19")) {
            if(new Date()<new Date(2025,0,1)) {
                window.location.replace("https://form.jotform.com/233092298380359")
            }
            else{
                window.location.replace("https://portal.agent22.eu/schaden")
            }
        },
        meta: {
            noAuth: true,
        },
    },
];

export default claimsRoutes;
